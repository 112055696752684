import { createAsyncThunk } from '@reduxjs/toolkit'
import { companiesServices } from '../../services/companiesServices'
import { CompanyFormType, UpdateInstanceCompanyArg, ValidateCompanyAccNumberRequestQuery } from '../../types/companiesTypes'
import { CustomErrorResponse } from '../../types/axiosTypes'
import { AlertColor } from '@mui/material'
import { addNotification } from '../reducers/notificationSlice'


const getCompanyList = createAsyncThunk(
  'companies/getCompanyList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await companiesServices.getCompanyList()

      return response
    } catch (e) {
      return rejectWithValue('test')
    }
  },
)

const getCompanyById = createAsyncThunk(
  'companies/getCompanyById',
  async (companyId: number, { rejectWithValue }) => {
    try {
      const response = await companiesServices.getCompanyById(companyId)

      return response
    } catch (e) {
      return rejectWithValue('Error')
    }
  },
)

const addCompany = createAsyncThunk(
  'companies/addCompany',
  async (body: CompanyFormType, { rejectWithValue, dispatch }) => {
    try {
      const response = await companiesServices.addCompany(body)

      dispatch(getCompanyList())

      return response
    } catch (e) {
      return rejectWithValue('Error')
    }
  },
)

const editCompany = createAsyncThunk(
  'companies/editCompany',
  async (arg: UpdateInstanceCompanyArg, { rejectWithValue, dispatch }) => {
    try {
      const response = await companiesServices.updatedCompany(arg)

      // dispatch(getCompanyList())

      return response
    } catch (e) {
      return rejectWithValue('Error')
    }
  },
)

const getAmlAccessTokenForCompany = createAsyncThunk(
  'companies/getAmlAccessTokenForCompany',
  async (companyId: number, { rejectWithValue }) => {
    try {
      const response = await companiesServices.getAmlAccessTokenForCompany(companyId)

      return response
    } catch (e) {
      return rejectWithValue('Error')
    }
  },
)

const validateCompanyAccNumber = createAsyncThunk(
  'companies/validateCompanyAccNumber',
  async (requestQuery: ValidateCompanyAccNumberRequestQuery, { rejectWithValue, dispatch }) => {
    try {
      const response = await companiesServices.validateCompanyAccNumber(requestQuery)

      return response
    } catch (e) {
      const err = e as CustomErrorResponse
      const notificationData = {
        open: true,
        type: 'error' as AlertColor,
        message: err.message,
        errorCode: err.errorCode,
      }

      dispatch(addNotification(notificationData))
      
      return rejectWithValue('Error')
    }
  },
)

export const companiesActionCreators = {
  getCompanyList,
  getCompanyById,
  addCompany,
  editCompany,
  getAmlAccessTokenForCompany,
  validateCompanyAccNumber,
}

import * as yup from 'yup'

const companyForm = yup.object({
  companyName: yup.string()
    .typeError('No Valid')
    .trim()
    .required('Required Field')
    .matches(/^[0-9a-zA-Z-\s&]*$/, 'Please, enter a valid Company Name'),
  accountNumber: yup.string()
    .typeError('No Valid')
    .trim()
    .required('Required Field')
    .matches(/^[0-9a-zA-Z]*$/, 'Please, enter a valid Account Number'),
  notificationEmail: yup.string()
    .typeError('Please, enter Email')
    .required('Required Field')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please, enter a valid Email')
})



export const companiesYupSchemes = {
  companyForm,
}